import './style.css';
import './App.css';
import { createRef, useEffect, useRef, useState } from 'react';
import { GovButton, GovFormInput, GovModal } from "@gov-design-system-ce/react";

function App() {
    const inputRefs = [
        createRef(),
        createRef(),
        createRef(),
        createRef(),
    ];

    const [data, setData] = useState(null);
    const [cardCode, setCardCode] = useState(null);
    const inputs = [];
    const [open, setOpen] = useState(false);
    const modalRef = useRef(null);
    const [isInvalid, setIsInvalid] = useState([true,true,true,true]);

    let year = new Date().getFullYear();

    useEffect(() => {
        const handleClose = (e) => {
            setOpen(false);
        }
        const {current: modalElement} = modalRef;
        if (modalElement) {
            modalElement.addEventListener('gov-close', handleClose)
        }
        return () => {
            if (modalElement) {
                modalElement.removeEventListener('gov-close', handleClose)
            }    
        }
    }, [])

    for (let i = 0; i < 4; i++) {
        inputs.push(
            <GovFormInput size="s" variant="primary" input-type="text" ref={inputRefs[i]}
                maxlength={4}
                key={i}
                pattern={"[A-Za-z0-9]{4}"}
                name={'input' + i}
                wcagLabelledBy={(i+1) + '. část identifikačního kodu průkazu'}
                invalid={!isInvalid[i]}
                success={isInvalid[i] && inputRefs[i].current?.value !== undefined ?  true : null}
                onGov-input={(e) => handleChange(e,i)}
                style={{width:'10ch'}}
                onKeyDown={handleEnter}>
            </GovFormInput>
       );
        if (i < 3) inputs.push(<span style={{marginRight: '4px', marginLeft:'4px'}} key={'span' + i}>-</span>)
    }

    function handleEnter(e) {
        if (e.keyCode === 13) {
            validate();
        } 
    }
    
    function handleChange(e,i) {
        // Realtime check code
      //  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g,'');
        // inputRefs[i].current.value = inputRefs[i].current.value.replace(/[^a-zA-Z0-9]/g,'');
        const nextIndex = i + 1;
        if (e.target.value.length === 4 && nextIndex < inputRefs.length) {

            const nextfield = document.querySelector(
                `input[name=input${nextIndex}]`
            );

            // If found, focus the next field
            if (nextfield !== null) {
                nextfield.focus();
            }
        }
        if (e.target.value.length === 4 && nextIndex === inputRefs.length) {

            const nextButton = document.querySelector(
                `button[class=element]`
            );

            // If found, focus the next field
            if (nextButton !== null) {
                nextButton.focus();
            }
        }
    }

    const handleShow = () => {
        setOpen(true);
    }


    function validate() {
        const cardCodeTmp = inputRefs.map(x => x.current.value).join('-').toUpperCase();
        setCardCode(cardCodeTmp);
       

        const testValid = inputRefs.map(a => !(!/^[A-Z0-9]{4}$/.test(a.current.value ? a.current.value.toUpperCase() : null)));
            setIsInvalid(testValid);

        const isCodeValid = testValid.every(value => value === true);
        console.log("isInvalid", isInvalid);
        console.log("testValid", testValid);
        console.log("isCodeValid", isCodeValid);

 
        // checks if the given code has valid format, ie. XXXX-XXXX-XXXX-XXXX
        if (!/^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/.test(cardCodeTmp)) {
            setData({ resultCode: 1 });
            return;
        }


        if (isCodeValid) {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/${cardCodeTmp}`)
            .then(response => response.json())
            .then((response_data) => {
                setData(response_data);
            });
        }
    }

    return (
<div  className="min-h-100v d-flex flex-column">
    <div className="csu-skip-to-content">
        <a href="#main-content">přejít k obsahu</a>
    </div>
    <header className="csu-header csu-header--light">
    <div className="csu-header__navbar">
        <div className="csu-container">
        <div className="csu-row align-items-center">
            <div className=" csu-col-auto">
                <a href="/" title="Český statistický úřad" className="csu-header-logo">
                    <img src="/csu-logo-cz.svg" alt="Český statistický úřad" width="203" height="50" />
                </a>
            </div>
        </div>
        </div>
    </div>
    </header>
    <div className="csu-container csu-container--py csu-container--py-md-m mb-24 mb-md-48">
    </div>
    <main id="main-content">
        <div className="csu-container mb-56 mb-lg-96">
          <div className="mb-32 mb-md-40">
            <div className="csu-row">
                <div className="csu-col-lg-10 csu-offset-lg-1">
                    <h1 className='h3 mb-0 fw-500 fw-md-600'>Ověření tazatele</h1>
                </div>
            </div>
          </div>
            <div className="mb-32 mb-lg-40">
                <div className="csu-section csu-section--bg-primary csu-section--container-expand csu-section--p-xl csu-section--text-l csu-section--outer pt-lg-24 pt-32 pb-lg-24 pb-32">
                    <div className="csu-row">
                    <div className="csu-col csu-col-lg-10 csu-offset-lg-1 last-child-no-margin">
                        <div className="fw-500 mw-10-12 csu-content">
                        <p>Pro zvýšení důvěrohodnosti vydává ČSÚ tazatelům „průkaz tazatele“, pomocí kterého prokazují své oprávnení k práci pro ČSÚ.
                            Pomocí identifikačního kódu průkazu lze ověřit číslo průkazu tazatele, jeho platnost a jméno a přijmení tazatele,
                            který lze porovnat s předloženým dokladem (s Průkazem zaměstnance ČSÚ nebo s občanským či jiným průkazem).</p>
                        </div>  
                    </div>
                    </div>
                </div>
                <div className="csu-section csu-section--bg-secondary csu-section--container-expand csu-section--no-gap csu-section--outer csu-section--no-top-radius pt-lg-40 pt-32 pb-lg-40 pb-32">
                    <div className="csu-row">
                        <div className="csu-col csu-col-lg-10 csu-offset-lg-1 last-child-no-margin">
                            <div className='csu-row csu-gy-2 mb-24'>
                                <div className="csu-col-md-3 last-child-no-margin">
                                    <p className='fw-600'>
                                        Vyplňte identifikační kód průkazu: 
                                    </p>
                                </div>
                                <div className="csu-col csu-col-md-5">
                                    <div className='csu-row csu-gy-2'>
                                    <div style={{display: 'flex', alignItems: 'center', gap:'4px'}}>
                                        {inputs}
                                    </div>
                                    </div>
                                </div>
                                <div className="csu-col csu-col-md-2">
                                    <GovButton wcag-label="Tlačítko pro odeslání ověření tazatele" variant="primary" type="solid" size="m" onGov-click={validate} expanded="true" className="text-lg-nowrap ml-8">Najít údaje průkazu</GovButton>
                                </div>
                            </div>           
                            <div className='csu-row csu-gy-2'>
                                <div className='csu-col' >
                                    {data && CardInfo(cardCode, data)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-56">
                <div className="csu-row">
                    <div className=" csu-col-lg-10 csu-offset-lg-1 last-child-no-margin">
                        <div className="csu-content">
                            <GovButton variant="primary" size="m" type="link" onGov-click={handleShow} wcag-label="Otevřít okno s informacemi">Vzor průkazu tazatele</GovButton>
                            <GovModal label="Vzor průkazu tazatele" wcag-close-label="Zavřít okno" label-tag='h1' open={open} ref={modalRef} className='csu-modal'>
                                <p>K aplikaci Ověření tazatele uvádíme platný vzor průkazu tazatele.
                                </p>
                                <div className="csu-row">
                                        <div className="csu-col-lg-5 text-center last-child-no-margin">
                                            <img src="/lic.jpg" alt="Český statistický úřad"  />
                                            <span>lícová strana</span>
                                        </div>
                                        <div className="csu-col-lg-5 text-center last-child-no-margin">
                                            <img src="/rub.jpg" alt="Český statistický úřad"  />
                                            <span>rubová strana</span>
                                        </div>
                                </div>
                            </GovModal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer className="csu-footer">
        <div className="csu-container">
            <div className="csu-footer_section">
                <div className="csu-footer_box">
                    <div className="csu-footer_insbox">
                        <img aria-label="Český statistický úřad" className="csu-logo-footer" src="/csu-logo-cz.svg" alt="Logo Český statistický úřad" />
                    </div>
                    <div className="csu-footer_text">&copy; Český statistický úřad (ČSÚ) | { year}</div>
                </div>
                <div className="csu-footer_text"><a href="https://www.czso.cz/csu/czso/ochrana-osobnich-udaju-gdpr">Ochrana osobních údajů</a></div>
            </div>
        </div>
    </footer>
</div>);
}

function CardInfo(cardCode, cardData) {
    const header = <div>Údaje pro průkaz s identifikačním kódem:<br />{cardCode}</div>;
    let result = null;
    let isOk = false;

   // cardData ={"card":{"id":2,"name":"Jan Novák","valid":true,"validFrom":"2020-06-01","validTo":"2026-06-01"},"resultCode":200,"resultMessage":"OK"}


    switch (cardData.resultCode) {
        case 1:
            result = <div>
                <div className='cartText'>Zadaný identifikační kód není správný.</div>
                <div className='cartValueError'>Pozor - neplatný kód!!!</div>
                </div>;
            break;

        case 2:
            result = <div>
                <div className='cartText'>K tomuto identifikačnímu kódu neexistuje platný průkaz.</div>
                <div className='cartValueError'>Pozor - neplatný průkaz!!!</div>
            </div>;
            break;

        case 3:
        case 4:
            result = <div>
                <div className='cartText'>K tomuto identifikačnímu kódu neexistuje platný průkaz.</div>
                <div className='cartValueError'>Pozor - neplatný průkaz!!!</div>
            </div>;
            break;

        default:
            isOk = true;
            
            result = <div>
                <div className='cartText'>Jméno a příjmení tazatele</div>
                <div className='cartValue'>{cardData.card.name}</div>
                <br />
                <div className='cartText'>Pořadové číslo průkazu</div>
                <div className='cartValue' style={{width: "45%"}}>{cardData.card.id}</div>
            </div>;
            break;
    }

    return (
        <div className={'cardResult ' + (isOk ? 'ok' : 'nok')}>
            <div className='header'>{header}</div>
            {result}
        </div>
    );
}

export default App;
